<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h1>Easter Egg #1</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <p>
          To unlock your 1st task enter the year when Born Again Strength was
          founded.
        </p>
      </v-col>
    </v-row>

    <h4>Task 1</h4>

    <EggTask :length="4" expected="2019" task="Box Jump" route="Egg1Task1" />

    <h4>Task 2</h4>

    <EggTask :length="5" expected="FORCE" task="Spikeball" route="Egg1Task2" />

    <h4>Task 3</h4>

    <EggTask :length="2" expected="T3" task="Nuke City" route="Egg1Task3" />

<h4>Prize</h4>
    <EggTask :length="5" expected="MOODY" task="Egg #1" route="Egg1Prize" />
  </v-container>
</template>

<script>
import EggTask from '@/components/EggTask.vue'

export default {
  components: { EggTask },
  data() {
    return {}
  },
}
</script>

<style></style>
