<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" md="4">
        <div>
          <v-otp-input v-model="actual" :length="length"></v-otp-input>
        </div>
      </v-col>

      <v-col cols="2" class="my-3">
        <v-btn :disabled="!isActive" :to="{ name: route }">{{ task }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    expected: String,
    route: String,
    length: Number,
    task: String,
  },
  data() {
    return {
      actual: '',
    }
  },
  computed: {
    isActive() {
      return this.actual === this.expected
    },
  },
}
</script>

<style></style>
